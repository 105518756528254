import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form', 'name', 'description']
  name;
  description;
  setValue = false;

  apply(event) {
    this.setValue = true;
    this.name = event.params.name
    this.description = event.params.description
    this.dispatch('apply', { detail: { boxId: event.params.boxId } })
  }

  formTargetConnected() {
    if (this.setValue) {
      this.nameTarget.value = this.name
      this.nameTarget.dispatchEvent(new Event('change'))

      if (this.description && this.descriptionTarget) {
        this.descriptionTarget.value = this.description
      }
    }
    this.setValue = false;
  }
}
