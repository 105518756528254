import { Controller } from '@hotwired/stimulus';
import _ from 'lodash'
import { post } from '@rails/request.js'

import { shapes, dia } from '@joint/core';

import { Options } from '../diagram/options';
import { DiagramUtility } from '../diagram/_utility';
import { ElementSelection } from '../diagram/element_selection';
import { ElementHover } from '../diagram/element_hover';
import { LinkHover } from '../diagram/link_hover';
import { ModelGraph } from '../diagram/model_graph';
export default class extends Controller {

  static targets = ['container', 'toolbar',
    'projectVariable', 'projectRelation',
    'newVariable', 'newRelation',
    'graphField', 'saveNotification', 'autosave', 'form']

  static values = {
    icons: Object,
    id: String,
    dataUrl: String,
    createRelationUrl: String
  }

  namespace = shapes
  graph
  paper
  elementSelection
  elementHover
  linkHover
  modelGraph
  dia = dia

  initialize() {
    const controller = this
    controller.graph = new dia.Graph({}, { cellNamespace: controller.namespace });
    controller.paper = new dia.Paper({
      el: controller.containerTarget,
      model: controller.graph,
      width: '100%',
      height: '100%',
      gridSize: 20,
      drawGrid: {
        name: 'doubleMesh',
        args: [
          { color: '#bcbcbc', thickness: 0.5 },
          { color: '#bcbcbc', thickness: 1, scaleFactor: 5 }
        ]
      },
      interactive: {
        labelMove: true,
        addLinkFromMagnet: false
      },
      cellViewNamespace: controller.namespace,
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      snapLinks: true,
      linkPinning: false,
      highlighting: {
        default: {
          name: 'mask',
          options: {
            attrs: {
              ...Options.highlighterAttributes
            }
          }
        }
      },
      defaultLink: () =>
        new shapes.standard.Link({
          attrs: {
            line: {
              ...Options.lineAttributes
            }
          },
          labels: [
            {
              attrs: {
                text: {
                  text: '',
                  ...Options.labelAttributes
                },
                rect: {
                  fill: '#ffffff00',
                  ...Options.linkLabelBackground
                }
              },
              position: Options.labelPosition
            }
          ],
          z: 2
        }),
      defaultConnectionPoint: {
        name: 'boundary',
        args: {
          extrapolate: true,
          sticky: true
        }
      },
      connectionStrategy: (end, view, _, coords) => {
        const { x, y } = DiagramUtility.getBoundaryPoint(coords, view)
        end.anchor = {
          name: 'topLeft',
          args: {
            dx: x,
            dy: y,
            rotate: true
          }
        }
      },
      padding: 20
    })

    controller.modelGraph = new ModelGraph(controller, controller.paper)
    controller.elementSelection = new ElementSelection(controller.paper, controller);
    controller.elementHover = new ElementHover(controller.paper, controller.graph, controller.iconsValue);
    controller.linkHover = new LinkHover(controller.paper, controller.iconsValue);

    controller.paper.on('blank:pointerdown', (_event, _x, _y) => {
      this.elementSelection.reset();
    })

    controller.paper.on('element:mouseenter', (elementView) => {
      controller.elementHover.add(elementView);
    });

    controller.paper.on('element:mouseleave', (elementView) => {
      controller.elementHover.reset(elementView);
    });

    controller.paper.on('link:mouseenter', (linkView) => {
      controller.linkHover.add(linkView)
    });

    controller.paper.on('link:mouseleave', (linkView) => {
      if (!linkView.hasTools('onhover')) return;
      controller.elementHover.reset(linkView);
    });

    controller.paper.on('link:connect', (linkView) => {
      this.createRelationRequest(linkView)
    })

    controller.paper.on('paper:pan', function (event, tx, ty) {
      event.preventDefault();
      event.stopPropagation();
      const { tx: tx0, ty: ty0 } = controller.paper.translate();
      controller.paper.translate(tx0 - tx, ty0 - ty);
    });

    controller.paper.on('cell:pointerclick', (elementView, _event) => {
      controller.elementSelection.reset();
      controller.elementSelection.add(elementView);
    })

    controller.graph.on('remove', (cell) => {
      if (cell.isLink()) {
        this.removeReletionFromModel(cell)
      } else {
        this.removeVariableFromModel(cell)
      }
    });
  }


  async setInModelDataRequest(urlString, elementName, elementId) {
    const url = new URL(urlString)
    let dData = new FormData();
    dData.set(`project_${elementName}[in_model]`, false);
    dData.set(`project_${elementName}[id]`, elementId);
    const response = await post(url, {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      console.log('okk')
    } else {
      console.log(response.response.statusText)
    }
  }

  removeVariableFromModel(cell) {
    const varId = cell.id.replace(/(^project_variable_)/gi, '')
    const urlString = `${window.location.origin}/projects/${this.idValue}/project_variables/update_from_model`
    this.setInModelDataRequest(urlString, 'variable', varId)
  }

  removeReletionFromModel(cell) {
    if (_.isNil(cell.attributes.relationId)) return

    const relId = cell.attributes.relationId.replace(/(^project_relation_)/gi, '')
    const urlString = `${window.location.origin}/projects/${this.idValue}/project_relations/update_from_model`
    this.setInModelDataRequest(urlString, 'relation', relId)
  }

  async createRelationRequest(linkView) {
    let target = linkView.model.getTargetCell()
    let source = linkView.model.getSourceCell()
    let dData = new FormData();
    dData.set('target_id', target.id.replace(/(^project_variable_)/gi, ''));
    dData.set('source_id', source.id.replace(/(^project_variable_)/gi, ''));
    dData.set('diagram_link_id', linkView.model.id);
    const response = await post(this.createRelationUrlValue, {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      console.log('okk')
    } else {
      console.log(response.response.statusText)
    }
  }

  containerTargetConnected() {
    const controller = this

    controller.paper.translate(20, 20);
    controller.modelGraph.load()
  }

  graphFieldTargetConnected() {
    const controller = this

    controller.graph.on('change', _element => {
      controller.graphFieldTarget.value = JSON.stringify(controller.graph.toJSON())
      controller.graphFieldTarget.dispatchEvent(new Event('change'))
    })

    controller.graph.on('remove', _element => {
      controller.graphFieldTarget.value = JSON.stringify(controller.graph.toJSON())
      controller.graphFieldTarget.dispatchEvent(new Event('change'))
    })
  }

  newVariableTargetConnected(target) {
    if (target.dataset.elementType == 'rectangle') { this._addRectangle(target.dataset) }
    if (target.dataset.elementType == 'ellipse') { this._addEllipse(target.dataset) }
  }

  newRelationTargetConnected(target) {
    const controller = this
    let allLinks = controller.graph.getLinks()
    let newLinks = allLinks.filter(el => { return el.id == target.dataset.diagramLinkId });
    if (_.isEmpty(newLinks)) { return }

    let oldLinks = allLinks.filter(el => { return el.attributes.relationId == target.dataset.relationId });
    let newLink = newLinks[0]

    if (_.isEmpty(oldLinks) && target.dataset.valid == 'true') {
      newLink.prop('labels/0/attrs/text/text', target.dataset.relationLabel)
      newLink.attributes.relationId = target.dataset.relationId
      newLink.attributes.name = target.dataset.relationName
    } else {
      newLink.remove()
    }
  }

  projectVariableTargetConnected(target) {
    const controller = this
    const action = target.dataset.action
    let cell = controller.graph.getCell(target.dataset.variableId)
    switch (action) {
      case 'remove':
        if (cell) cell.remove()
        break
      case 'create_or_update':
        this._createOrUpdateElement(target.dataset)
        break
      default:
      // code block
    }
  }

  projectRelationTargetConnected(target) {
    const action = target.dataset.action
    let allLinks = this.graph.getLinks()
    let thisLink = allLinks.filter(el => { return el.attributes.relationId == target.dataset.relationId })
    switch (action) {
      case 'remove':
        if (_.isEmpty(thisLink)) return
        thisLink[0].remove()
        break;
      case 'create_or_update':
        this._createOrUpdateRelation(target.dataset)
        break;
      default:
      // code block
    }
  }

  _addRectangle(data) {
    const controller = this
    const node = { id: data.variableId, name: data.variableName }
    let element = controller.modelGraph.makeRectangle(node)
    element.addTo(controller.graph);
    let freeSpace = controller._findFreeSpace(element.attributes.size.width, element.attributes.size.height);
    element.position(freeSpace.x, freeSpace.y);
  }

  _addEllipse(data) {
    const controller = this
    const node = { id: data.variableId, name: data.variableName }
    let element = controller.modelGraph.makeEllipse(node)
    element.addTo(controller.graph);
    let freeSpace = controller._findFreeSpace(element.attributes.size.width, element.attributes.size.height);
    element.position(freeSpace.x, freeSpace.y);
  }

  _addLink(data) {
    const controller = this
    const relation = {
      id: data.relationId,
      label: data.relationLabel || '',
      source: data.relationSource,
      target: data.relationTarget,
      name: data.relationName || ''
    }
    let element = controller.modelGraph.makeRelation(relation)
    element.addTo(controller.graph);
  }

  _createOrUpdateElement(data) {
    let element = this.graph.getCell(data.variableId)
    if (element) {
      element.prop('attrs/label/text', data.variableName)
    } else {
      this._addEllipse(data)
    }
  }

  _createOrUpdateRelation(data) {
    let source = this.graph.getCell(data.sourceId)
    if (_.isNil(source)) {
      this._addEllipse({ variableId: data.sourceId, variableName: data.sourceName })
    }
    let target = this.graph.getCell(data.targetId)
    if (_.isNil(target)) {
      this._addEllipse({ variableId: data.targetId, variableName: data.targetName })
    }
    let allLinks = this.graph.getLinks()
    let thisLink = allLinks.filter(el => { return el.attributes.relationId == data.relationId });
    if (_.isEmpty(thisLink)) {
      this._addLink(data)
    } else {
      let link = thisLink[0]
      link.prop('labels/0/attrs/text/text', data.relationLabel)
      link.attributes.name = data.relationName
    }
  }

  zoomIn(_event) {
    let currentScale = this.paper.scale().sx
    if (currentScale > 3) return
    this.paper.scale(0.25 + currentScale)
  }

  zoomOut(_event) {
    let currentScale = this.paper.scale().sx
    if (currentScale <= 0.25) return
    this.paper.scale(currentScale - 0.25)
  }

  resetZoom(_event) {
    this.paper.scale(1)
  }

  restetModelData() {
    this.modelGraph.build();
    this.elementSelection.reset();
  }

  exportToPNG() {
    this.modelGraph.graphToPNG()
  }

  _findFreeSpace(width, height) {
    const controller = this
    let x = 50;
    let y = 50;

    controller.paper.model.getElements().forEach(element => {
      let bbox = element.getBBox();
      if (x + width > bbox.x && x < bbox.x + bbox.width && y + height > bbox.y && y < bbox.y + bbox.height) {
        x = bbox.x + bbox.width + 10;
        y = bbox.y + bbox.height + 10;
      }
    });

    return { x: x, y: y };
  }
}