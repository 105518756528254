import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  flash(payload) {
    const flash = this._buildFlash(payload.detail)

    document.getElementById('flash_messages').appendChild(flash)
  }

  _buildFlash(payload) {
    const { message, wrapperClass, icons } = payload

    const icon = document.createElement('i')
    icon.classList.add(...icons)

    const close = document.createElement('a')
    close.href = 'javascript:void(0)'
    close.classList.add('close')
    close.dataset.dismiss = 'alert'
    close.dataset.label = 'close'
    close.dataset.basicTarget = 'closeButton'

    const wrapper = document.createElement('div')
    wrapper.classList.add('alert', 'alert-dismissible', wrapperClass)
    wrapper.append(close)
    wrapper.append(icon)
    if (_.isArray(message)) {
      message.forEach(msg => {
        wrapper.append(msg)
      });
    } else {
      wrapper.append(message)
    }

    const container = document.createElement('div')
    container.classList.add('flash-message-container')
    container.appendChild(wrapper)

    return container
  }
}
