import { Controller } from '@hotwired/stimulus'
import SlimSelect from 'slim-select'
import { csrfToken } from 'helpers';

export default class extends Controller {
  static targets = ['selectBox', 'fieldWithOptions', 'multiselect', 'field', 'autocomplete']
  static values = { url: String }
  connect() {}

  fieldTargetConnected(target) {
    let arrayOptions = Array.from(target.options)
    let placeholder = arrayOptions.filter(item => item.value === '')
    let allowDeselect = (placeholder.length > 0)
    let sSelect = new SlimSelect({
      select: target,
      selected: true,
      addToBody: true,
      settings: {
        searchHighlight: true,
        allowDeselect: allowDeselect,
        placeholderText: target.dataset.placeholder || 'Select Value...'
      }
    })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  fieldTargetDisconnected(target) {
    target.slim.destroy()
  }

  fieldWithOptionsTargetConnected(target) {
    let data = [];
    let placeholder = ''
    Array.from(target.options).forEach((item) => {
      let disabled = (item.dataset.notSelectable == 'true') ? true : false;
      if (item.value == "") { placeholder = item.text }
      let innerHTML = '';
      if (item.dataset.html) {
        innerHTML += item.dataset.html
      }
      delete item.dataset.html;
      if (item.value == "") {
        data.push({
          value: '',
          text: '',
          data: { placeholder: true }
        })
      } else {
        data.push({
          value: item.value,
          text: item.text,
          disabled: disabled,
          innerHTML: item.text + innerHTML,
          selected: item.selected,
          data: item.dataset
        })
      }
    })
    let sSelect = new SlimSelect({ placeholder: placeholder, select: target,
      selected: true, data: data, addToBody: true, allowDeselect: target.dataset.allowDeselect === 'true' })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  fieldWithOptionsTargetDisconnected(target) {
    target.slim.destroy()
  }

  multiselectTargetConnected(target) {
    new SlimSelect({ select: target, selected: true, showSearch: false, addToBody: true })
  }

  multiselectTargetDisconnected(target) {
    target.slim.destroy()
  }

  autocompleteTargetConnected(target) {
    const controller = this

    let arrayOptions = Array.from(target.options)
    let placeholder = arrayOptions.filter(item => item.value === '')
    let allowDeselect = (placeholder.length > 0)
    let sSelect = new SlimSelect({
      select: target,
      selected: true,
      addToBody: true,
      settings: {
        searchHighlight: true,
        allowDeselect: allowDeselect,
        placeholderText: target.dataset.placeholder || 'Select Value...'
      },
      events: {
        search: (search) => {
          return new Promise((resolve, reject) => {
            if (search.length < 2) {
              return reject('Search must be at least 2 characters')
            }
            fetch(controller.urlValue, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken(),
              },
              body: JSON.stringify({ query: search }),
            })
              .then((response) => response.json())
              .then((data) => {
                const options = data
                  .map((option) => {
                    return {
                      text: `${option.name}`,
                      value: `${option.id}`,
                    }
                  })
                resolve(options)
              })
          })
        },
      }
    })
    if (target.hasAttribute('readonly')) { sSelect.disable() }
  }

  autocompleteTargetDisconnected(target) {
    target.slim.destroy()
  }

  selectBoxTargetConnected(element) {
    console.log(element)
  }

  selectBoxTargetDisconnected(element) {
    console.log(element)
  }
}
