import { Controller } from '@hotwired/stimulus'
import { flashEffect } from 'helpers/effects'

export default class extends Controller {
  clone() {
    const destination = this._buildDestinationTarget()

    this._copyFormValues(this.element, destination)

    this._updateEid(destination)

    this._copySentence(this.element, destination)

    flashEffect(this, {
      message: 'Sentence cloned successfully' ,
      wrapperClass: 'alert-info',
      icons: ['fa-solid', 'fa-triangle-exclamation']
    })
  }

  _lookupNestedFormController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element.closest('[data-controller*="nested-form"]'),
      'nested-form'
    )
  }

  _buildDestinationTarget() {
    const nestedForm = this._lookupNestedFormController()
    nestedForm.add(new CustomEvent('click'))

    // nested-form creates an element as a sibling of targetTarget
    const node = nestedForm.targetTarget.previousElementSibling

    const wrapperNode = this.element.parentNode.parentNode
    // move the new node after the current element's parent
    return wrapperNode.insertBefore(node, this.element.parentNode.nextSibling)
  }

  _copyFormValues(source, destination) {
    this._copyInputValues(source, destination, 'select')
    this._copyInputValues(source, destination, 'input')
  }

  _copyInputValues(source, destination, fieldType) {
    destination.querySelectorAll(fieldType).forEach((destInput) => {
      destInput.value = source.querySelector(`${fieldType}[name="${CSS.escape(destInput.name)}"]`).value
    })
  }

  _copySentence(source, destination) {
    const sourceSen = source.querySelector('[data-annotate-target="text"]')
    const destSen = destination.querySelector('[data-annotate-target="text"]')

    destSen.innerHTML = sourceSen.innerHTML
  }

  _updateEid(destination) {
    const eid = this._randomId()
    destination.dataset.eid = eid
    destination.querySelector('[data-field-name="eid"]').value = eid
  }

  _randomId() {
    const array = new Uint32Array(1)

    crypto.getRandomValues(array)

    return array[0]
  }
}
