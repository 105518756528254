import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'


export default class extends Controller {
  static targets = [ 'form', 'field', 'input', 'content',
    'resetButton', 'selectedValues', 'range' ]

  static values = {
    complexForm: Boolean
  }

  connect() {
    if (this.hasFieldTarget) {
      this.fieldTargets.forEach((el) => this._appendTag(el))
    }
    if (this.hasInputTarget) {
      this.inputTargets.forEach((el) => this._appendTag(el))
    }
    if (this.hasRangeTarget) {
      this.rangeTargets.forEach((el) => this._appendTag(el))
    }
  }

  inputTargetConnected(element) {
    const length = element.value.length
    if (length > 0) { this.resetButtonTarget.style.display = 'inline-block'; }
    element.addEventListener('input', this.toggleResetButton.bind(this));
    element.addEventListener('change', (event) => {
      if (this.hasComplexFormValue) {
        this.formTarget.requestSubmit()
      }
      this._removeTag(event.currentTarget)
      this._appendTag(event.currentTarget)
    });
  }

  fieldTargetConnected(element) {
    element.addEventListener('change', (event) => {
      this._apply(event.currentTarget)
    });
  }

  rangeTargetConnected(target) {
    flatpickr(target, {
      mode: 'range',
      onChange: (selectedDates, dateStr, instance) => {
        dateStr;
        instance;
        if (selectedDates.length === 2) {
          this._apply(target)
        }
      }
    })
  }

  _apply(element) {
    this.formTarget.requestSubmit()
    this._removeTag(element)
    this._appendTag(element)
  }

  reset() {
    this.inputTargets.forEach((el) => {
      el.value = ''
      this._removeTag(el)
    })
    this.formTarget.requestSubmit()
    this.resetButtonTarget.style.display = 'none';
  }

  resetSelectedFilter(event) {
    let fieldName = event.currentTarget.dataset.fieldName
    let searchField = document.getElementById(`study_search_${fieldName}`)
    if (!searchField) { return }
    searchField.value = ''
    this.formTarget.requestSubmit()
    let tag = event.currentTarget.closest('.tag')
    tag.remove()
  }

  toggleResetButton() {
    if (this.inputTarget.value.length > 0) {
      this.resetButtonTarget.style.display = 'inline-block';
    } else {
      this.resetButtonTarget.style.display = 'none';
    }
  }

  toggleContent(_event) {
    if (this.hasContentTarget) {
      this.contentTarget.classList.toggle('active')
    }
  }

  _tagHTML(name, value, valueText) {
    const tag = document.createElement('div')
    tag.classList.add('tag')
    tag.dataset.fieldName = name

    const close = document.createElement('a')
    close.href = 'javascript:void(0)'
    close.classList.add('close-button', 'close')
    close.dataset.action = "click->admin--filters#resetSelectedFilter"
    close.dataset.fieldName = name
    close.fieldValue = value

    tag.append(valueText, close)
    return tag
  }

  _getFieldName(element) {
    return element.id.replace('study_search_', '');
  }

  _appendTag(element) {
    if (!this.hasSelectedValuesTarget) { return }
    if (!element.value) { return }
    let fieldName = this._getFieldName(element)
    if (element.tagName.toLowerCase() === 'input') {
      this.selectedValuesTarget.append(this._tagHTML(fieldName, element.value, element.value))
    } else if (element.tagName.toLowerCase() === 'select') {
      let textValue = element.selectedOptions[0].text
      this.selectedValuesTarget.append(this._tagHTML(fieldName, element.value, textValue))
    } else if (element.tagName.toLowerCase() === 'textarea') {
      console.log('Found a textarea element:', element);
    } else if (element.tagName.toLowerCase() === 'button') {
      console.log('Found a button element:', element);
    }
  }

  _removeTag(element) {
    if (!this.hasSelectedValuesTarget) { return }
    let tags = this.selectedValuesTarget.querySelectorAll('.tag')
    let fieldName = this._getFieldName(element)
    const matchingElement = Array.from(tags).find(tag => tag.dataset.fieldName === fieldName);
    if (matchingElement) { matchingElement.remove() }
  }
}
