import { Controller } from '@hotwired/stimulus'
import { shuffleArray, titleize } from 'helpers'
import ApexCharts from 'apexcharts'
export default class extends Controller {
  static targets = ['chartArea', 'singleChartArea', 'barChart', 'radialBar']
  static values = {
    chartData: Object,
    title: String,
    showXaxis: Boolean
  }

  colors = [
    '#3874eb',
    '#0EAD69',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#008FFB',
    '#546E7A',
    '#D4526E',
    '#2B908F',
    '#540D6E',
    '#4CAF50',
    '#f000b9',
    '#F86624',
    '#3F51B5'
  ]
  chartAreaTargetConnected (target) {
    const options = {
      chart: {
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      stroke: {
        curve: 'straight'
      },
      series: this._seriesData(this.chartDataValue),
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.chartDataValue.dates,
        labels: {
          show: this.hasShowXaxisValue ? this.showXaxisValue : true,
        },
        tooltip: {
          enabled: this.hasShowXaxisValue ? this.showXaxisValue : true,
        },
      },
      yaxis: {
        tickAmount: 5,
        min: 0
      },
      legend: {
        show: false
      },

      fill: {
        opacity: 1
      },
      colors: shuffleArray(this.colors),
      title: {
        text: this.titleValue || ''
      },
      tooltip: {
        shared: false,
        fixed: {
          enabled: true
        }
      },
    }
    const chart = new ApexCharts(target, options)
    chart.render()
  }

  singleChartAreaTargetConnected (target) {
    const options = {
      chart: {
        type: 'area',
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      series: [
        {
          name: 'Cumulatives',
          data: this.chartDataValue.cumulatives
        }
      ],
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: this.chartDataValue.dates,
        labels: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: false
        },
        min: 0
      },
      legend: {
        show: false
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 1
      },
      colors: shuffleArray(this.colors),
      tooltip: {
        fixed: {
          enabled: true
        }
      },
      title: {
        text: this.titleValue || '',
      },
      subtitle: {
        text: this.chartDataValue.human_readable_total || '',
        offsetX: 0,
        offsetY: this.titleValue == '' ? 0 : 18,
        style: {
          fontSize: '22px'
        }
      }
    }
    const chart = new ApexCharts(target, options)
    chart.render()
  }

  barChartTargetConnected (target) {
    const options = {
      series: [
        {
          data: this.chartDataValue.data
        }
      ],
      chart: {
        type: 'bar',
        height: '300px',
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: 'bottom',
          },
          endingShape: 'rounded'
        }
      },
      colors: this.colors,
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        style: {
          colors: ['#fff']
        },
        formatter: function (val, opt) {
          return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
        },
        offsetX: 0
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        offsetX: 0,
        labels: {
          show: false
        },
        categories: this.chartDataValue.categories
      },
      yaxis: {
        labels: {
          show: false
        }
      },
      legend: {
        show: false
      },
      title: {
        text: this.titleValue || '',
        margin: 0,
        fontSize: '2px'
      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return ''
            }
          }
        }
      }
    }
    const chart = new ApexCharts(target, options)
    chart.render()
  }

  radialBarTargetConnected (target) {
    const options = {
      series: this.chartDataValue.data,
      chart: {
        // height: 250,
        type: 'radialBar',
        hollow: {
          margin: 5,
          size: '100%'
        },
        offsetX: 0,
        offsetY: 0,
        margin: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      },
      grid: {
        padding: {
          left: 5,
          right: 5
        }
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px'
            },
            value: {
              fontSize: '16px'
            }
          }
        }
      },
      title: {
        text: this.titleValue || ''
      },
      labels: this.chartDataValue.categories
    }

    const chart = new ApexCharts(target, options)
    chart.render()
  }

  _extractDataForSeries(obj) {
    const result = {};
    Object.keys(obj).forEach(key => {
        if (key !== 'dates' && Array.isArray(obj[key])) {
            result[key] = obj[key];
        }
    });
    return result;
  }

  _seriesData(obj) {
    const data = []
    Object.keys(this._extractDataForSeries(obj)).forEach(key => {
      data.push({name: titleize(key), data: obj[key]})
    });
    return data
  }
}
