import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  loader;
  static targets = ['column', 'columnRight' ]

  connect() {
    this.loader = document.querySelector('.page-loader');
    this.resizeColumnsWidth()
    this.resizeRowsHeight()
    window.addEventListener('beforeunload', (_event) => {
      localStorage.removeItem('studyPanelColumnWidth');
      localStorage.removeItem('studyFormColumnWidth');
    });
    if (this.element.classList.contains('content-wrapper')) {
      if (!this.hasColumnTarget) { return }
      this.columnTarget.style.width = '100%'
      if (!this.hasColumnRightTarget) { return }
      this.columnRightTarget.style.width = '0'
    }
  }

  resizeColumnsWidth() {
    if (!this.hasColumnTarget) { return }

    const resizers = this.columnTarget.querySelectorAll('.resizer.vertical');

    let startX = 0;
    let startWidth = 0;
    const minimumSize = 10

    const mouseDownHandler = (e) => {
      e.preventDefault()
      startX = e.clientX;
      const styles = window.getComputedStyle(this.columnTarget);
      startWidth = parseInt(styles.width, 10);
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      const endWidth = e.clientX - startX;
      let columnWidth = startWidth + endWidth
      if (columnWidth > minimumSize) {
        this.columnTarget.style.width = `${columnWidth}px`;
        this.columnRightTarget.style.width = `calc(100% - ${columnWidth}px)`;
        if (this.columnTarget.classList.contains('studies')) {
          localStorage.setItem('studyPanelColumnWidth', columnWidth)
        }
        if (this.columnTarget.classList.contains('form-column')) {
          localStorage.setItem('studyFormColumnWidth', columnWidth)
        }
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizers.forEach((resizer) => {
      resizer.addEventListener('mousedown', mouseDownHandler);
    })
  }

  resizeRowsHeight() {
    const resizers = document.querySelectorAll('.resizer.horizontal');
    let wrapper = null;
    let rowTop = null;
    let startY = 0;
    let startHeight = 0;
    const minimumSize = 10

    const mouseDownHandler = (e) => {
      wrapper = e.currentTarget.closest('.column-view')
      rowTop = wrapper.querySelector('.row.top')
      e.preventDefault()
      startY = e.clientY;
      const styles = window.getComputedStyle(rowTop);
      startHeight = parseInt(styles.height, 10);

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      const endHeight = e.clientY - startY;
      let rowHeight = startHeight + endHeight
      if (rowHeight > minimumSize) {
        rowTop.style.height = `${rowHeight}px`;
        let column = rowTop.closest('.column-view')
        let bottom = column.querySelector('.row.bottom')
        let bottomHeight = column.offsetHeight - rowHeight - 8
        bottom.style.height = `${bottomHeight}px`
        let columnName = rowTop.closest('.column').dataset.name
        localStorage.setItem(`${columnName}RowTopHeight`, rowHeight)
        localStorage.setItem(`${columnName}RowBottomHeight`, bottomHeight)
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    resizers.forEach((resizer) => {
      resizer.addEventListener('mousedown', mouseDownHandler);
    })
  }

  columnRightTargetConnected(target) {
    this.resizeColumnsWidth()
    if (localStorage.getItem('studyPanelColumnWidth') && target.classList.contains('overlay')) {
      let columnWidth = localStorage.getItem('studyPanelColumnWidth')
      this.columnTarget.style.width = `${columnWidth}px`;
      this.columnRightTarget.style.width = `calc(100% - ${columnWidth}px)`;
    }
    if (localStorage.getItem('studyFormColumnWidth') && target.classList.contains('form-column')) {
      let columnWidth = localStorage.getItem('studyFormColumnWidth')
      this.columnTarget.style.width = `${columnWidth}px`;
      this.columnRightTarget.style.width = `calc(100% - ${columnWidth}px)`;
    }
  }

  columnTargetConnected() {}

  resetView(event) {
    event.preventDefault;
    localStorage.removeItem('studyPanelColumnWidth');
    localStorage.removeItem('studyFormColumnWidth');
  }
}
