import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'form', 'field', 'inputField' ]

  inputFieldTargetConnected(target) {
    const controller = this
    let parent = target.closest('.form-field')
    target.addEventListener('focusin', (_event) => {
      parent.classList.add('focused')
    })
    target.addEventListener('focusout', (_event) => {
      parent.classList.remove('focused')
      controller._formFieldHasValue(target)
    })
    controller._formFieldHasValue(target)
  }

  submit(event) {
    if (event.detail.success) {
      console.log('Form successfully submitted')
    } else {
      console.log('Error!')
    }
  }

  inlineSubmitForm() {
    this.formTarget.requestSubmit()
  }

  _formFieldHasValue(field) {
    let parent = field.closest('.form-field')
    if (field && field.value) {
      parent.classList.add('has-value')
    } else {
      parent.classList.remove('has-value')
    }
  }
}