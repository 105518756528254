import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr'
// import { Dutch } from "flatpickr/dist/l10n/nl.js"

export default class extends Controller {
  static targets = [ 'date', 'time', 'datetime', 'range' ]
  static values = { default: String, defaultRange: String, max: String, min: String }

  connect() {
  }

  dateTargetConnected(target) {
    let options = this._dateOptions(target)

    if (this.hasMaxValue) { options.maxDate = this.maxValue }
    if (this.hasMinValue) { options.minDate = this.minValue }
    if (this.hasDefaultValue) { options.defaultDate = this.defaultValue }

    flatpickr(this.element, options)
  }

  timeTargetConnected(target) {
    let options = {}
    if (this.hasDefaultValue) { options.defaultDate = this.defaultValue }

    flatpickr(target, options)
  }

  timeTargetDisconnected(target) {
    target.flatpickr().destroy()
  }

  rangeTargetConnected(target) {
    // let options = {
    //   mode: 'range',
    //   onChange: function(selectedDates, dateStr, instance) {

    //     if (selectedDates.length === 2) {
    //       console.log("Both dates selected: ", selectedDates);
    //     }
    //   }
    // }

    flatpickr(target, {
      mode: 'range',
      onChange: (selectedDates, dateStr, instance) => {
        dateStr;
        instance;
        if (selectedDates.length === 2) {
          console.log("Both dates selected: ", selectedDates);
        }
      }
    })
  }

  _dateOptions(target) {
    return {
      altInput: true,
      dateFormat: "Y-m-d",
      altFormat: "d-m-Y",
      allowInput: true,
      // locale: Dutch,
      altInputClass: target.id
    }
  }
}
