
export function showModal(boxId) {
  const box = document.getElementById(`modal_${boxId}_dialog`);
  box.classList.add('modal-show');
  box.classList.add('zoom-in');
  box.classList.remove('modal-hide');
  box.classList.remove('zoom-out');
}

export function hideModal(boxId) {
  // const allAlerts = document.getElementsByClassName('alert-error');
  // Array.allAlerts.forEach(link => { link.classList.remove('alert-error') })
  const box = document.getElementById(boxId);
  box.classList.remove('zoom-in');
  box.classList.add('zoom-out');
  setTimeout((() => {
    box.classList.remove('modal-show');
    box.classList.add('modal-hide');
  }), 200);
}