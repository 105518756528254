import { Controller } from '@hotwired/stimulus'
import { addDialog, removeDialog } from '../helpers/dialog_registry'
import { showModal, hideModal } from '../helpers/modals'

export default class extends Controller {
  static targets = ['form']
  loader;
  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  submit(event) {
    if (event.detail.success) {
      const box = event.currentTarget.closest('.modal-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      this.hide(boxId);
      frame.src = ''
    } else {
      this.loader.style.display = 'none';
    }
  }

  inlineSubmit(event) {
    if (event.detail.success) {
      const box = event.currentTarget.closest('.modal-content-wrapper')
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = ''
    } else {
      this.loader.style.display = 'none';
    }
  }

  disconnect() {}

  closeOnKey(event) {
    if (!(event instanceof KeyboardEvent)) { return; }

    this.close(event);
  }

  close(event) {
    event.preventDefault()
    const box = event.target.closest('.modal-content-wrapper')
    const boxId = box.id
    this.hide(boxId);
    if (box) {
      const frameId = box.dataset.frameId;
      if (frameId) {
        const frame = document.querySelector(`turbo-frame#${frameId}`);
        frame.src = '';
      }
    }
  }

  show(event) {
    if (event.ctrlKey) {
      return false;
    }
    // detail from other controller event
    // currentTarget from click
    const boxId = event.detail.boxId || event.currentTarget.dataset.boxId
    addDialog(`modal_${boxId}_dialog`)
    showModal(boxId)
  }

  hide(boxId) {
    hideModal(boxId)
    removeDialog(boxId)
  }
}
