import { Controller } from '@hotwired/stimulus'
import { showModal, hideModal } from '../helpers/modals'
export default class extends Controller {
  static targets = ['saveNotification', 'autosave', 'form']

  connect () {
    this.boundHandleBeforeFrameRender = this.handleBeforeFrameRender.bind(this);

    window.addEventListener('beforeunload', this.boundHandleBeforeFrameRender);
    document.addEventListener('turbo:before-frame-render', this.boundHandleBeforeFrameRender);
  }

  disconnect () {
    window.removeEventListener('beforeunload', this.boundHandleBeforeFrameRender);
    document.removeEventListener('turbo:before-frame-render', this.boundHandleBeforeFrameRender);
  }

  autosaveTargetConnected () {
    this._formSubmit()
  }

  showConfirmationModal () {
    showModal('confirmation')
    return new Promise(resolve => {
      this.resolve = resolve
    })
  }

  saveAction () {
    this.resolve('save')
    this._formSubmit()
  }

  cancelAction () {
    this.resolve('cancel')
    this._resetFormState()
  }

  closeAction () {
    this.resolve('close')
    hideModal('modal_confirmation_dialog')
  }

  _formSubmit () {
    const button = this.formTarget.querySelector('button[type="submit"]')
    button.click()
    this._resetFormState()
  }

  enableSaveButton () {
    this.saveNotificationTarget.classList.add('active')
    this._setHasUnsavedChanges()
  }

  _resetFormState () {
    this._resetGraphState()
    hideModal('modal_confirmation_dialog')
  }

  async handleBeforeFrameRender (event) {
    if (this._projectFrames().includes(event.target.id)) { return }

    const hasUnsavedChanges = localStorage.getItem('graphHasUnsavedChanges')
    if (hasUnsavedChanges === 'true') {
      this._resetGraphState()
      event.preventDefault()
      const decision = await this.showConfirmationModal()
      if (decision === 'save') {
        event.detail.resume()
      } else if (decision === 'close') {
        console.log(decision)
      } else {
        event.detail.resume()
      }
    }
  }

  _setHasUnsavedChanges () {
    localStorage.setItem('graphHasUnsavedChanges', true)
  }

  _resetGraphState () {
    localStorage.setItem('graphHasUnsavedChanges', false)
  }

  _graphHasUnsavedChanges () {
    const hasUnsavedChanges = localStorage.getItem('graphHasUnsavedChanges')
    return hasUnsavedChanges === 'true'
  }

  _projectFrames () {
    return [
      'project_entities',
      'project_relations_search_results',
      'project_variables_search_results',
      'project_entities_misc',
      'project_theories_search_results',
      'project_keywords_search_results'
    ]
  }
}
