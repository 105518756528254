import { g, V } from '@joint/core/';

export class Options {

  static bodyAttributes = {
    fill: '#ffffff',
    stroke: '#555555',
    strokeWidth: 1,
    cursor: 'grab',
  }

  static lineAttributes = {
    stroke: '#000000',
    strokeWidth: 1,
    // targetMarker: {
    //   d: Options.normalizeMarker('M 0 0 L 8 -4 L 8 4 z', -5)
    // }
  };

  static labelAttributes = {
    fontSize: 12,
    fill: '#000000'
  };

  static labelPosition = {
    offset: -15,
    rotate: true,
    args: {
      keepGradient: true
    }
  }

  static linkLabelBackground = {
    stroke: '#ffffff00',
    strokeWidth: 2,
    refWidth: '100%',
    refHeight: '100%'
  }

  static elementsLabelAttributes = {
    cursor: 'grab',
    textWrap: {
      width: 'calc(w - 40)',
      height: 'calc(h - 15)',
      ellipsis: true,
    }
  }

  static highlighterAttributes = {
    stroke: '#4666E5',
    strokeWidth: 2,
    strokeLinecap: 'butt',
    strokeLinejoin: 'miter',
  };

  static normalizeMarker(d, offset) {
    var path = new g.Path(V.normalizePathData(d));
    var bbox = path.bbox();
    var ty = - bbox.height / 2 - bbox.y;
    var tx = - bbox.width / 2 - bbox.x;
    if (typeof offset === 'number') tx -= offset;
    path.translate(tx, ty);
    return path.serialize();
  }
}