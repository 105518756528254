import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';
import _ from 'lodash';
import { flashEffect } from 'helpers/effects'

export default class extends Controller {
  static targets = ['actions', 'text', 'textOverlay', 'input', 'field', 'index']

  connect() {
    this._renderHighlights()
  }

  disconnect() {
    this.destroy()
  }

  inputTargetConnected(element) {
    if (element.value) {
      element.readOnly = false
      element.dataset.previousValue = element.value
    }
  }

  indexTargetConnected(element) {
    const input = this._findInput(element.dataset.tag)

    if (!element.value) {
      const match = this.textTarget.innerText.trim().match(this._highlightRegexp(input.value))

      if (match) { element.value = match.index }
    }
  }

  tooltip(event) {
    if (window.getSelection().isCollapsed) {
      return this.destroy()
    }

    if (!this.textOverlayTarget.contains(event.target)) { return }

    this.destroy()
    this.tooltipInstance = this._initializeTippy(event.target)
  }

  destroy() {
    if (!this.tooltipInstance) { return }

    this.tooltipInstance.destroy()
    this.tooltipInstance = null
  }

  update({ params }) {
    if (!params.tag) { return }

    const selection = window.getSelection()
    const phrase = selection.toString()
    // depending on the selection direction, focus can be smaller than anchor
    const indexValue = Math.min(selection.anchorOffset, selection.focusOffset)

    if (phrase.trim() === '') { return }

    if (!this._matchesText(phrase) || this._overlapsIndex(indexValue, params.tag)) {
      flashEffect(this, {
        message: 'Selection invalid, please update',
        wrapperClass: 'alert-error',
        icons: ['fa-solid', 'fa-triangle-exclamation']
      })
      return
    }

    const input = this._findInput(params.tag)
    input.value = phrase
    input.readOnly = false
    input.dataset.previousValue = phrase

    const indexInput = this._findIndexInput(params.tag)
    indexInput.value = indexValue

    this._renderHighlights()
  }

  updateText({ target }) {
    if (!target.dataset.previousValue) { return }

    const previousValue = target.dataset.previousValue

    if (target.value.trim()) {
      this._removeHighlights()

      const indexInput = this._findIndexInput(target.dataset.tag)
      const begin = Number(indexInput.value)
      const end = begin + previousValue.length
      this.textTarget.innerHTML = this._replaceAt(this.textTarget.innerHTML.trim(), target.value,
                                                  begin, end)

      // increment indexes for highlights after the current is updated
      this.indexTargets.forEach((el) => {
        // ignore index before the current
        if (Number(el.value) <= begin) { return }

        const newIndex = Number(el.value) + (target.value.length - previousValue.length)
        el.value = (newIndex < 0) ? 0 : newIndex
      })

      this.textOverlayTarget.innerHTML = this.textTarget.innerHTML
      target.dataset.previousValue = target.value

      this.fieldTarget.value = this.textTarget.innerText

      this._renderHighlights()
    } else {
      target.readOnly = true
    }
  }

  _initializeTippy(element) {
    return tippy(element, {
      content: this.actionsTarget.innerHTML,
      theme: 'light',
      allowHTML: true,
      interactive: true,
      zIndex: 999999,
      showOnCreate: true,
      placement: 'top',
      trigger: 'manual',
      onClickOutside(instance,) {
        if (instance.state.isVisible) {
          instance.hide()
        }
        if (!instance.state.isDestroyed) {
          instance.destroy()
        }
      }
    })
  }

  _highlightFragment(phrase, tag) {
    if (phrase.trim() === '') { return }

    let content = null
    const phraseMark = `<mark class="tag-${tag}">${phrase}</mark>`

    const indexInput = this._findIndexInput(tag)

    if (indexInput.value) {
      const begin = Number(indexInput.value)
      const end = Number(indexInput.value) + phrase.length

      content = this._replaceAt(this.textTarget.innerHTML.trim(), phraseMark,
                                begin, end)
    } else {
      content = this.textTarget.innerHTML.replace(
        this._highlightRegexp(phrase),
        phraseMark
      )
    }

    this.textTarget.innerHTML = content
  }

  _replaceAt(text, replacement, indexBegin, indexEnd) {
    return text.slice(0, indexBegin) + replacement + text.slice(indexEnd)
  }

  _matchesText(phrase) {
    return this.textTarget.innerHTML.match(this._highlightRegexp(phrase))
  }

  _overlapsIndex(index, tag) {
    return this.indexTargets.some((el) => {
      if (el.dataset.tag === tag) { return false }

      const input = this._findInput(el.dataset.tag)
      const begin = Number(el.value)
      const end = begin + input.value.length

      return index >= begin && index <= end
    })

  }

  _highlightRegexp(phrase) {
    return new RegExp(_.escapeRegExp(phrase), 'i')
  }

  _renderHighlights() {
    this._removeHighlights()

    // render highlights in reverse order,
    // ignoring duplicate indexes
    this.indexTargets.filter((value, index, self) => index === self.findIndex((t) => ( t.value === value.value)))
    .toSorted((a, b) => Number(b.value) - Number(a.value)).forEach((el) => {
      const input = this._findInput(el.dataset.tag)
      this._highlightFragment(input.value, el.dataset.tag)
    })
  }

  _removeHighlights() {
    this.textTarget.querySelectorAll('mark').forEach((mark) => {
      this.textTarget.innerHTML = this.textTarget.innerHTML.replaceAll(
        mark.outerHTML, mark.textContent
      )
    })
  }

  _findInput(tag) {
    return this.inputTargets.find((el) => el.dataset.tag === tag)
  }

  _findIndexInput(tag) {
    return this.indexTargets.find((el) => el.dataset.tag === tag)
  }
}
