import { Controller } from '@hotwired/stimulus'
import { csrfToken } from 'helpers'

export default class extends Controller {
  static targets = ['content'];
  static values = { url: String, variable: String };

  toggleContent(event) {
    const controller = this
    let button = event.currentTarget
    if (controller.hasContentTarget) {
      if (controller.contentTarget.classList.contains('hidden')) {
        controller.contentTarget.classList.remove('hidden');
        this._setExpanded(button)
      } else {
        controller.contentTarget.classList.add('hidden');
        this._setCollapsed(button)
      }
    } else {
      controller._fetchData()
      controller._setExpanded(button)
    }
  }

  disconnect() {

  }

  _setExpanded(button) {
    let icon = button.querySelector('i.indicator.icon');
    button._tippy.setContent('Collapse')
    icon.classList.remove('collapsed')
    icon.classList.add('expanded')
  }

  _setCollapsed(button) {
    let icon = button.querySelector('i.indicator.icon');
    button._tippy.setContent('Expand')
    icon.classList.remove('expanded')
    icon.classList.add('collapsed')
  }

  _fetchData() {
    const controller = this
    let statements = localStorage.getItem('statementsForSuggestion')
    const data = {
      statements: statements,
      variable: controller.variableValue
    }
    fetch(controller.urlValue, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
    })
    .then((r) => r.text())
    .then((html) => {
      const fragment = document
        .createRange()
        .createContextualFragment(html);
      controller.element.appendChild(fragment);
    });
  }
}