import { Controller } from '@hotwired/stimulus'
import { shapes, dia } from '@joint/core'
import { ModelGraph } from '../diagram/model_graph'

export default class extends Controller {
  static targets = ['container', 'graphField']
  static values = { dataUrl: String, type: { type: String, default: 'full' } }

  graph
  paper
  modelGraph

  initialize () {
    const controller = this
    controller.graph = new dia.Graph({}, { cellNamespace: shapes })

    controller.paper = new dia.Paper({
      el: controller.containerTarget,
      model: controller.graph,
      width: '100%',
      height: '100%',
      gridSize: 20,
      drawGrid: {
        name: 'doubleMesh',
        args: [
          { color: '#bcbcbc', thickness: 0.5 },
          { color: '#bcbcbc', thickness: 1, scaleFactor: 5 }
        ]
      },
      interactive: false
    })
    controller.modelGraph = new ModelGraph(controller, controller.paper)
    controller.paper.on('paper:pan', function(event, tx, ty) {
      if (controller.typeValue != 'full') { return }

      event.preventDefault();
      event.stopPropagation();
      const { tx: tx0, ty: ty0 } = controller.paper.translate();
      controller.paper.translate(tx0 - tx, ty0 - ty);
    });
  }

  containerTargetConnected () {
    const controller = this

    if (controller.typeValue == 'full') {
      controller.paper.translate(20, 20)
    } else {
      controller.paper.translate(5, 5)
    }

    controller.modelGraph.load()

    if (controller.typeValue == 'thumbnail') {
      this.paper.scale(0.5)
    }
  }

  zoomIn() {
    let currentScale = this.paper.scale().sx
    if (currentScale > 3) return
    this.paper.scale(0.25 + currentScale)
  }

  zoomOut() {
    let currentScale = this.paper.scale().sx
    if (currentScale <= 0.25) return
    this.paper.scale(currentScale - 0.25)
  }

  resetZoom() {
    this.paper.scale(1)
  }

  exportToPNG() {
    this.modelGraph.graphToPNG()
  }
}
