import { Controller } from '@hotwired/stimulus'
import { showModal, hideModal } from '../../helpers/modals';
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ['form', 'title', 'status', 'field', 'removeButton']
  static values = { enabled: Boolean }

  formChanged = false
  itemRemoved = false

  connect() {
    this.setFormHeight()
  }

  setFormHeight() {
    let columnWrapper = document.querySelector('.overlay .column-wrapper')
    let columnWrapperHeight = 0
    if (columnWrapper) {
      columnWrapperHeight = columnWrapper.offsetHeight
    } else {
      let headerHeight = document.querySelector('header').offsetHeight
      columnWrapperHeight = window.innerHeight - headerHeight
    }
    let formHeight = columnWrapperHeight - this.titleTarget.offsetHeight
    this.formTarget.style.height = `${formHeight}px`
  }

  setStatus(event) {
    if (!this.enabledValue) { return }

    this.statusTarget.value = event.currentTarget.value
    this.formChanged = true
  }

  fieldTargetConnected(target) {
    if (!this.enabledValue) { return }

    const controller = this
    target.addEventListener('change', (_event) => {
      controller.formChanged = true
    })
  }

  removeItem() {
    if (!this.enabledValue) { return }

    const controller = this
    controller.formChanged = true
    controller.itemRemoved = true
  }

  formTargetConnected(element) {
    const controller = this
    document.addEventListener('click', (event)=> {
      if (!controller.enabledValue) { return }
      if (event.target.classList && event.target.classList.contains('summary-close')) { return }

      if (!element.contains(event.target)) {
        if (!controller.formChanged) { return }
        if (controller.itemRemoved) {
          controller.itemRemoved = false
          return
        }
        event.preventDefault()
        event.stopPropagation()
        showModal('confirmation')
      } else {
        if (!controller.formChanged) { return }
        if ((event.target.dataset.turboFrame == 'admin_studies_summary') || (event.target.id == 'rollback_parsing_result')){
          event.preventDefault()
          showModal('confirmation')
        }
      }
    })
  }

  resetFormState() {
    if (!this.enabledValue) { return }

    this.formChanged = false
    hideModal('modal_confirmation_dialog')
  }

  formSubmit() {
    if (!this.enabledValue) { return }

    Rails.fire(this.formTarget, 'submit');
    this.resetFormState()
  }

  removeFrame(event) {
    const frame = event.currentTarget.closest('turbo-frame');
    frame.src = ''
    frame.innerHTML = '';
  }
}
