import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "submit" ]

  connect() {
    this.toggleSubmit()
  }

  toggleSubmit() {
    const hasCheckedCheckbox = this.element.querySelectorAll('input[type=checkbox]:checked').length > 0
    this.submitTarget.disabled = !hasCheckedCheckbox
  }
}
