import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'

export default class extends Controller {
  static targets = ['frame', 'backButton']

  connect() {}

  frameTargetConnected(target) {
    let frame = target.closest('turbo-frame')
    let url = frame.getAttribute('src');
    let bredcrumbsList = this._getList()
    let lastUrl = this._getLastUrl(bredcrumbsList)
    if (!_.isNil(lastUrl) || (!_.isUndefined(lastUrl))) {
      if (lastUrl == url) {
        bredcrumbsList.pop()
        this._setLocalStorage(bredcrumbsList)
        let nBredcrumbsList = this._getList()
        let nLastUrl = this._getLastUrl(nBredcrumbsList)
        if (!_.isNil(nLastUrl)) {
          this.backButtonTarget.href = nLastUrl
          this.backButtonTarget.classList.remove('hidden')
        }
      } else {
        this.backButtonTarget.href = lastUrl
        this.backButtonTarget.classList.remove('hidden')
      }
    }
    this._setBreadcrumbs(url)
  }

  removeLast(_event) {
    let bredcrumbsList = this._getList()
    bredcrumbsList.pop()
    this._setLocalStorage(bredcrumbsList)
  }

  hideFrame(event) {
    let frameBox = event.currentTarget.closest('.single-box')
    frameBox.classList.add('hidden')
    this.resetBreadcrumbs()
  }

  resetBreadcrumbs() {
    this._setLocalStorage([])
  }

  _setBreadcrumbs(url) {
    let bredcrumbsList = this._getList()
    let lastUrl = this._getLastUrl(bredcrumbsList)
    if (lastUrl === url) {
      bredcrumbsList.pop()
    } else {
      bredcrumbsList.push(url);
    }
    this._setLocalStorage(bredcrumbsList)
  }

  _getList() {
    let bredcrumbs = localStorage.getItem('projectEntityBredcrumbs')
    let bredcrumbsList = []
    if (!(_.isNil(bredcrumbs)) || !(_.isEmpty(bredcrumbs))) {
      bredcrumbsList = JSON.parse(bredcrumbs)
    }
    return bredcrumbsList
  }

  _getLastUrl(bredcrumbsList) {
    return bredcrumbsList[bredcrumbsList.length - 1];
  }

  _setLocalStorage(bredcrumbsList) {
    let bredcrumbs = JSON.stringify(bredcrumbsList)
    localStorage.setItem('projectEntityBredcrumbs', bredcrumbs)
  }
}