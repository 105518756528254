
import { shapes } from '@joint/core';
import { DirectedGraph } from '@joint/layout-directed-graph/dist/DirectedGraph'
import { Options } from './options';
import { saveAs } from 'file-saver';

import _ from 'lodash'
export class ModelGraph {

  paper;
  link;
  icons;
  graph;
  controller;
  modelDataUrl;

  constructor(controller, paper) {
    this.controller = controller;
    this.paper = paper;
    this.graph = controller.graph;
    this.icons = controller.iconsValue;
    this.modelDataUrl = controller.dataUrlValue;
  }

  load() {
    let graphData = this.controller.graphFieldTarget.value
    if (_.isEmpty(graphData)) {
      this.build();
    } else {
      this.graph.clear();
      this.graph.fromJSON(JSON.parse(graphData))
    }
  }

  async build() {
    let elements = [];
    let relations = [];
    const results = await this.loadData()

    let variables = this._graphElementsWithSettings(results.elements)
    let graphRelations = this._graphRelationsWithSettings(results.relations)
    if (_.isEmpty(variables)) {
      this.graph.resetCells([]);
      return
    }
    variables.forEach(node => {
      if (node.type == 'standard.Rectangle') {
        elements.push(this.makeRectangle(node));
      } else {
        elements.push(this.makeEllipse(node));
      }
    });

    graphRelations.forEach(relation => {
      relations.push(this.makeRelation(relation))
    });

    this.graph.resetCells(elements.concat(relations));
    DirectedGraph.layout(elements.concat(relations), this._directGraphOptions());
  }

  async loadData() {
    const url = new URL(this.modelDataUrl)
    const response = await fetch(url);
    return await response.json();
  }

  makeRelation(relation) {
    let link = new shapes.standard.Link({
      source: { id: relation.source },
      target: { id: relation.target },
      id: relation.id,
      relationId: relation.id,
      name: relation.name,
      attrs: {
        relationId: relation.id,
        line: {
          stroke: relation.lineStroke || '#000000',
          strokeWidth: relation.lineStrokeWidth || 1,
        },
      },
      labels: [{
        attrs: {
          text: {
            fontSize: relation.fontSize || 12,
            fill: relation.labelFill || '#000000',
            text: relation.label,
          },
          rect: {
            fill: '#ffffff00',
            ...Options.linkLabelBackground
          }
        },
        position: Options.labelPosition
      }],
      z: 2
    });
    return link;
  }

  makeRectangle(node) {
    let maxLineLength = node.name.length
    let letterSize = node.fontSize || 12;
    let modelWidth = maxLineLength * (letterSize - 1)
    let element = new shapes.standard.Rectangle({
      id: node.id,
      size: { width: modelWidth, height: 40 },
      attrs: {
        body: {
          rx: 5, ry: 5,
          fill: node.bodyFill || '#ffffff',
          stroke: node.bodyStroke || '#555555',
          strokeWidth: node.bodyStrokeWidth || 1,
          cursor: 'grab',
        },
        label: {
          text: node.name,
          fontSize: letterSize,
          lineHeight: node.lineHeight || '16px',
          fill: node.labelFill || '#000000',
          ...Options.elementsLabelAttributes,
        },
      },
    })
    return element
  }

  makeEllipse(node) {
    let nameLength = node.name.length
    let letterSize = node.fontSize || 12;
    let modelWidth = nameLength > 20 ? 185 : (nameLength * letterSize + 20)
    let rows = node.name.match(/.{1,20}/g) || [];
    let rowLength = rows.length
    let element = new shapes.standard.Ellipse({
      id: node.id,
      size: { width: modelWidth, height: (rowLength * letterSize) + 48 },
      root: {
        highlighterSelector: 'body'
      },
      attrs: {
        body: {
          fill: node.bodyFill || '#ffffff',
          stroke: node.bodyStroke || '#555555',
          strokeWidth: node.bodyStrokeWidth || 1,
          cursor: 'grab',
        },
        label: {
          text: node.name,
          fontSize: letterSize,
          fill: node.labelFill || '#000000',
          lineHeight: node.lineHeight || '16px',
          ...Options.elementsLabelAttributes,
        },
        root: {
          highlighterSelector: "body"
        },
      },
      markup: [{
        tagName: 'ellipse',
        selector: 'body'
      }, {
        tagName: 'text',
        selector: 'label'
      }]
    })
    return element
  }

  _directGraphOptions() {
    return {
      setLinkVertices: false,
      rankDir: 'lr',
      nodeSep: 50,
      rankSep: 50,
      edgeSep: 20
    }
  }

  graphToPNG() {
    const canvas = document.createElement('canvas');
    canvas.width = this.paper.getComputedSize().width
    canvas.height = this.paper.getComputedSize().height
    const ctx = canvas.getContext('2d');
    const serializer = new XMLSerializer()
    const svgString = serializer.serializeToString(this.paper.svg);

    let svgBlob = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    let url = URL.createObjectURL(svgBlob);
    let img = new Image();
    img.onload = function() {
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);
      canvas.toBlob(function(blob) {
        saveAs(blob, 'model-image.png');
      }, 'image/png');
    };
    img.src = url;
  }

  _getGraphElementsData() {
    const graphElements = this.graph.getElements()
    const mapData = graphElements.map(element => {
      return {
        id: element.id,
        bodyFill: element.attributes.attrs.body.fill,
        bodyStroke: element.attributes.attrs.body.stroke,
        bodyStrokeWidth: element.attributes.attrs.body.strokeWidth,
        fontSize: element.attributes.attrs.label.fontSize,
        lineHeight: element.attributes.attrs.label.lineHeight,
        labelFill: element.attributes.attrs.label.fill,
        type: element.attributes.type
      }
    })
    return mapData
  }

  _graphElementsWithSettings(data) {
    let variables = []
    data.forEach(element => {
      const gData = this._getGraphElementsData().filter(e => (e.id == element.id))
      let variable = element
      if (gData.length > 0) {
        variable = Object.assign(gData[0], element)
      }
      variables.push(variable)
    })
    return variables
  }

  _graphRelationsWithSettings(data) {
    let relations = []
    data.forEach(element => {
      const gData = this._getGraphRelationsData().filter(e => (e.id == element.id))
      let relation = element
      if (gData.length > 0) {
        relation = Object.assign(gData[0], element)
      }
      relations.push(relation)
    })
    return relations
  }

  _getGraphRelationsData() {
    const graphLinks = this.graph.getLinks()
    const mapData = graphLinks.map(link => {
      let label = link.label()
      return {
        id: link.attributes.relationId,
        lineStroke: link.attributes.attrs.line.stroke,
        lineStrokeWidth: link.attributes.attrs.line.strokeWidth,
        fontSize: label.attrs.text.fontSize,
        labelFill: label.attrs.text.fill,
        type: link.attributes.type
      }
    })
    return mapData
  }
}