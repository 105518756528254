import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide';

export default class extends Controller {
  static targets = ['slider']

  sliderTargetConnected(target) {
    let slide = new Splide(target, {
      autoWidth: true,
      height: '220px',
      gap: '5px',
      pagination: false,
      classes: {
        arrows: 'splide__arrows your-class-arrows',
        arrow: 'splide__arrow your-class-arrow',
        prev: 'splide__arrow--prev your-class-prev',
        next: 'splide__arrow--next your-class-next',
      },
    })
    slide.mount();
  }
}