
import { highlighters } from '@joint/core';
import { Options } from './options';
import { put, post, FetchRequest } from '@rails/request.js'

export class ElementSelection {

  controller;
  paper;
  element;
  toolbar;
  linkToolbar;
  bgColorInput;
  textInput;
  textColorInput;
  fontSizeInput;
  lineHeightInput;
  strokeColorInput;
  strokeWidthInput;
  linkTextInput;
  linkTextColorInput;
  linkFontSizeInput;
  linkAngleInput;
  linkKeepGradientInput;
  linkStrokeColorInput;
  linkStrokeWidthInput;
  highlighter;

  constructor(paper, controller) {
    this.paper = paper;
    this.controller = controller;
    this.toolbar = document.getElementById('element_toolbar')
    this.linkToolbar = document.getElementById('link_toolbar')

    this.bgColorInput = document.getElementById('bg_color')
    this.textInput = document.getElementById('text')
    this.textColorInput = document.getElementById('text_color')
    this.fontSizeInput = document.getElementById('font_size')
    this.lineHeightInput = document.getElementById('line_height')
    this.strokeColorInput = document.getElementById('stroke_color')
    this.strokeWidthInput = document.getElementById('stroke_width')

    this.linkTextInput = document.getElementById('link_text')
    this.linkTextColorInput = document.getElementById('link_text_color')
    this.linkFontSizeInput = document.getElementById('link_font_size')
    this.linkAngleInput = document.getElementById('link_angle')
    this.linkKeepGradientInput = document.getElementById('link_keep_gradient')
    this.linkStrokeColorInput = document.getElementById('link_stroke_color')
    this.linkStrokeWidthInput = document.getElementById('link_stroke_width')

    this._changeOptionsEventHandler()
    this.highlighter = highlighters.mask;
  }

  add(el) {
    this.element = el;
    this._tools();
  }

  reset() {
    this.remove(this.element)
  }

  remove(el) {
    if (this.element != null) {
      this._clearElement();
    }
    this.element = null;
  }

  _tools() {
    this._showItemToolbar()
    this.highlighter.add(this.element.model.findView(this.paper), {
      selector: 'body'
    }, this.element.model.id, {
      layer: 'back',
      attrs: {
        ...Options.highlighterAttributes
      }
    });
  }

  _clearElement() {
    this.highlighter.remove(this.element)
    this._closeItemToolbar()
  }
  _showItemToolbar() {
    const type  = this.element.model.get('type')
    switch(type) {
      case 'standard.Rectangle':
        this._showElementToolbar()
        break;
      case 'standard.Ellipse':
        this._showElementToolbar()
        break;
      case 'standard.Link':
        this._showLinkToolbar()
        break
      case 'link':
        this._showLinkToolbar()
        break
      default:
        // code block
    }

  }
  _showElementToolbar() {
    this.controller.toolbarTarget.classList.remove('hidden')
    this.toolbar.classList.remove('hidden')
    let attrs = this.element.model.attributes.attrs

    this.bgColorInput.value = attrs.body.fill;
    this.textColorInput.value = attrs.label.fill;
    this.textInput.value = attrs.label.text;
    this.fontSizeInput.value = attrs.label.fontSize;
    this.lineHeightInput.value = attrs.label.lineHeight.replace('px', '');

    this.strokeColorInput.value = attrs.body.stroke;
    this.strokeWidthInput.value = attrs.body.strokeWidth;
  }

  _showLinkToolbar() {
    this.controller.toolbarTarget.classList.remove('hidden')
    const name = document.getElementById('relation_name')
    this.linkToolbar.classList.remove('hidden')
    let label = this.element.model.label()
    let attrs = this.element.model.attributes.attrs
    name.innerHTML = this.element.model.attributes.name

    this.linkTextInput.value = label.attrs.text.text
    this.linkTextColorInput.value = label.attrs.text.fill;
    this.linkFontSizeInput.value = label.attrs.text.fontSize;
    this.linkAngleInput.value = label.position.angle || 0;
    this.linkKeepGradientInput.checked = label.position.args.keepGradient
    this.linkStrokeColorInput.value = attrs.line.stroke;
    this.linkStrokeWidthInput.value = attrs.line.strokeWidth;
  }

  _closeItemToolbar = () => {
    this.controller.toolbarTarget.classList.add('hidden')
    this.toolbar.classList.add('hidden')
    this.linkToolbar.classList.add('hidden')
  }

  changeElementOption = (event, path) => {
    this.element.model.prop(path, event.currentTarget.value)
  }

  changeText = (_event) => {
    this.updateVariableRequest({key: 'name', value: this.textInput.value}, 'attrs/label/text')
  }

  changeLineHeight = (_event) => {
    this.element.model.prop('attrs/label/lineHeight', `${this.lineHeightInput.value}px`)
  }

  changeLinkText = (_event) => {
    this.updateRelationRequest({key: 'abbr', value: this.linkTextInput.value}, 'labels/0/attrs/text/text')
  }

  linkKeepGradient = () => {
    this.element.model.prop('labels/0/position/args/keepGradient', this.linkKeepGradientInput.checked)
  }

  async updateRelationRequest(params={}, path) {
    const relId = this.element.model.attributes.relationId.replace(/(^project_relation_)/gi, '')
    const urlString = `${window.location.origin}/projects/${this.controller.idValue}/project_relations/update_from_model`
    const url = new URL(urlString)
    let dData =  new FormData();
    dData.set(`project_relation[${params.key}]`, params.value);
    dData.set('project_relation[id]', relId);
    const response = await post(url, {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.element.model.prop(path, params.value)
    } else {
      console.log(response.response.statusText)
    }
  }

  async updateVariableRequest(params={}, path) {
    const varId = this.element.model.id.replace(/(^project_variable_)/gi, '')
    const urlString = `${window.location.origin}/projects/${this.controller.idValue}/project_variables/update_from_model`
    const url = new URL(urlString)
    let dData =  new FormData();
    let value = (params.key == 'name') ? params.value.trim().replace(/\s+/g, " ") : params.value
    dData.set(`project_variable[${params.key}]`, value);
    dData.set('project_variable[id]', varId);
    const response = await post(url, {
      query: dData,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.element.model.prop(path, params.value)
    } else {
      console.log(response.response.statusText)
    }
  }

  _changeOptionsEventHandler = () => {
    this.bgColorInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'attrs/body/fill')
    })
    this.textColorInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'attrs/label/fill')
    })
    this.textInput.addEventListener('change', this.changeText)
    this.fontSizeInput.addEventListener('change', (event) => {
      this.changeElementOption(event, 'attrs/label/fontSize')
    })
    this.lineHeightInput.addEventListener('input', this.changeLineHeight)
    this.strokeColorInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'attrs/body/stroke')
    })
    this.strokeWidthInput.addEventListener('change', (event) => {
      this.changeElementOption(event, 'attrs/body/strokeWidth')
    })
    this.linkTextInput.addEventListener('change', this.changeLinkText)

    this.linkTextColorInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'labels/0/attrs/text/fill')
    })
    this.linkFontSizeInput.addEventListener('change', (event) => {
      this.changeElementOption(event, 'labels/0/attrs/text/fontSize')
    })
    this.linkAngleInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'labels/0/position/angle')
    })
    this.linkKeepGradientInput.addEventListener('change', this.linkKeepGradient)
    this.linkStrokeColorInput.addEventListener('input', (event) => {
      this.changeElementOption(event, 'attrs/line/stroke')
    })
    this.linkStrokeWidthInput.addEventListener('change', (event) => {
      this.changeElementOption(event, 'attrs/line/strokeWidth')
    })
  }
}