import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['noDataMessage', 'item']
  static classes = ['hidden']

  connect() {
    if (this.hasItemTarget) {
      this.noDataMessageTarget.classList.add(this.hiddenClass)
    }
  }

  itemTargetConnected() {
    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.add(this.hiddenClass)
    }
  }

  itemTargetDisconnected() {
    if (this.hasItemTarget) { return }

    if (this.hasNoDataMessageTarget) {
      this.noDataMessageTarget.classList.remove(this.hiddenClass)
    }
  }
}
