import { Controller } from '@hotwired/stimulus'
import Inputmask from 'inputmask'

export default class extends Controller {
  static targets = ['integer', 'angle']
  static values = {
    rightAlign: Boolean,
    max: Number,
    min: Number
  }

  integerTargetConnected() {
    this.mask = Inputmask('integer', {
      rightAlign: this.rightAlignValue || false,
      min: this.minValue || false,
      max: this.maxValue || false,
    }).mask(this.integerTarget)
  }

  angleTargetConnected(target) {
    this.mask = Inputmask('decimal', { min: -360, max: 360 }).mask(target)
  }
}
