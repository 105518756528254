import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    // preselect first tab as active
    const tabId = this.currentExistingTab || this.allTabsLinks[0].dataset.tab;
    this._setActiveTab(tabId);
  }

  change(event) {
    let tabId = event.currentTarget.dataset.tab
    this._setActiveTab(tabId)
  }

  switchTo({ params }) {
    this._setActiveTab(params.tabId);
  }

  _setActiveTab(tabId) {
    this.allTabsLinks.forEach(link => {
      link.classList.remove('current');
    })
    this.allTabsContents.forEach(tab => {
      tab.classList.remove('current');
    })
    this.element.querySelector(`.tab-link[data-tab="${tabId}"]`).classList.add('current');
    this.element.querySelector(`#${tabId}`).classList.add('current')
    localStorage.setItem('studyFormCurrentTab', tabId);
  }

  get allTabsLinks() {
    return this.element.querySelectorAll('ul.tabs li');
  }

  get allTabsContents() {
    return this.element.querySelectorAll('.tab-content');
  }

  get currentExistingTab() {
    if (this.element.querySelector(`#${this.currentTab}`)) {
      return this.currentTab
    }
    return null
  }

  get currentTab() {
    return localStorage.getItem('studyFormCurrentTab')
  }
}
