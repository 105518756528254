
import { linkTools, dia, g } from '@joint/core';
export class LinkHover {

  paper;
  link;
  icons;

  constructor(paper, icons) {
    this.paper = paper;
    this.icons = icons;
  }

  add(linkView) {
    this.link = linkView;
    this._tools();
  }

  reset(linkView) {
    this.link = linkView;
    this.link.removeTools()
    this.link = null;
  }

  _tools() {
    if (this.link == null) return;
    const aLinkTools = [
      new linkTools.Vertices({
        redundancyRemoval: false,
        snapRadius: 30,
        vertexAdding: false,
      }),
      new linkTools.Segments(),
      new linkTools.Remove(),
      new linkTools.TargetArrowhead(),
    ];

    this.link.addTools(new dia.ToolsView({
      name: 'onhover',
      tools: aLinkTools
    }));
  }
}