import { g } from '@joint/core';

export class DiagramUtility {
  static getBoundaryPoint(point, view, snapRadius = 20) {
    const bbox = view.model.getBBox();
    const angle = view.model.angle();
    // Relative to the element's position
    const relPoint = point.clone().rotate(bbox.center(), angle).difference(bbox.topLeft());
    const relBBox = new g.Rect(0, 0, bbox.width, bbox.height);
    if (!relBBox.containsPoint(relPoint)) {
        const relCenter = relBBox.center();
        const relTop = relBBox.topMiddle();
        const relLeft = relBBox.leftMiddle();
        if (Math.abs(relTop.x - relPoint.x) < snapRadius) {
            return (relCenter.y > relPoint.y) ? relTop : relBBox.bottomMiddle();
        }
        if (Math.abs(relLeft.y - relPoint.y) < snapRadius) {
            return (relCenter.x > relPoint.x) ? relLeft : relBBox.rightMiddle();
        }
    }
    return this.getClosestBoundaryPoint(relBBox, view, relPoint);
  }

  static getClosestBoundaryPoint(bbox, view, point) {
    const type = view.model.get('type')
    if (type === 'standard.Ellipse') {
      const ellipse = g.Ellipse.fromRect(bbox);
      return ellipse.intersectionWithLineFromCenterToPoint(point);
    } else {
      return bbox.pointNearestToPoint(point);
    }
  }
}