// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Rails from '@rails/ujs';
import * as ActiveStorage from "@rails/activestorage"
import 'channels';
import 'controllers';

import { Turbo } from '@hotwired/turbo-rails';

Rails.start()
ActiveStorage.start()

Turbo.start()
Turbo.setProgressBarDelay(5)

document.addEventListener("turbo:frame-load", function (event) {
  let firstFormField = event.target.querySelector(
    'input:not(.skip-first-field-focus):not([type="hidden"]), select, textarea, .button'
  )

  if (firstFormField) {
    firstFormField.setAttribute('tabindex', '0');
    firstFormField.focus();
  }
})

document.addEventListener('turbo:before-frame-render', function() {
  const loader = document.querySelector('.page-loader')
  if (loader) loader.style.display = 'block'
})

document.addEventListener('turbo:frame-render', function() {
  const loader = document.querySelector('.page-loader')
  if (loader) loader.style.display = 'none'
})

document.addEventListener('turbo:submit-start', function() {
  const loader = document.querySelector('.page-loader')
  if (loader) loader.style.display = 'block'
})

document.addEventListener('turbo:submit-end', function() {
  const loader = document.querySelector('.page-loader')
  if (loader) loader.style.display = 'none'
})