import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['title']
  static values = {
    url: String,
    frameId: String,
    similarityBy: String
  }

  titleTargetConnected(element) {
    this._loadSuggestions({ target: element })
    element.addEventListener('change', this._loadSuggestions.bind(this))
  }

  _loadSuggestions({ target }) {
    if (!target.value) { return }

    const url = new URL(this.urlValue)
    url.searchParams.append('title', target.value)
    url.searchParams.append('frame_id', this.frameIdValue)
    url.searchParams.append('similarity_by', this.similarityByValue)

    Turbo.visit(url, { frame: this.frameIdValue })
  }
}
