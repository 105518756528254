import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']
  static values = {
    timeout: { type: Number, default: 500 },
    inputMinLength: { type: Number, default: 3 }
  }

  inputTargetConnected(target) {
    const controller = this

    if (target.value.length > 0) {
      controller._expandBox()
    }

    target.addEventListener('input', function() {
      controller._expandBox()
    });
  }

  submitInput() {
    if (!this._shouldSubmit()) { return }

    if (this.timeoutId) { clearTimeout(this.timeoutId) }

    this.timeoutId = setTimeout(this.submit.bind(this), this.timeoutValue)
  }

  reset() {
    this.inputTarget.value = ''
    this._collapseBox()
    this._doSubmit(this.inputTarget)
  }

  submit() {
    if (!this._shouldSubmit()) { return }

    this._doSubmit(this.inputTarget)
    this._expandBox()
  }

  applyFilter(fieldName, value) {
    this.setFilterValue(fieldName, value)
    this.submitInput({})
  }

  setFilterValue(fieldName, value) {
    const input = this.element.querySelector(`[name=${fieldName}]`)
    input.value = value
  }

  _doSubmit(input) {
    input.form.requestSubmit()
  }

  _shouldSubmit() {
    return this.inputTarget.value.length >= this.inputMinLengthValue
  }

  _expandBox() {
    this.element.classList.remove('collapsed')
  }

  _collapseBox() {
    this.element.classList.add('collapsed')
    this.element.querySelectorAll('.item').forEach(e => e.remove())
  }
}
