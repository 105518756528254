
import { elementTools, dia, g, highlighters } from '@joint/core';
export class ElementHover {

  paper;
  element;
  controller;
  graph;
  icons;
  highlighter;

  constructor(paper, graph, icons) {
    this.paper = paper;
    this.graph = graph;
    this.icons = icons;
    this.highlighter = highlighters.mask;
  }

  add(el) {
    this.element = el;
    this._tools();
    this.element.highlight(null, {
      highlighter: {
        name: 'addClass',
        options: { className: 'element-hover-highlighter' }
      }
    });
  }

  reset(el) {
    this.element = el;
    this.element.removeTools()
    this.element = null;
  }

  ResizeTool = elementTools.Control.extend({
    children: [
      {
        tagName: 'image',
        selector: 'handle',
        attributes: {
          cursor: 'nwse-resize',
          width: 20,
          height: 20,
          opacity: 0.6,
          class: 'your-css-class',
        }
      },
      {
        tagName: 'rect',
        selector: 'extras',
        attributes: {
          pointerEvents: 'none',
          fill: 'none',
          stroke: '#33334F',
          strokeDasharray: '1,3',
          strokeWidth: '0.5',
          rx: 5,
          ry: 5
        }
      }
    ],
    getPosition: (view) => {
      const model = view.model;
      const { width, height } = model.size();
      return { x: width, y: height };
    },
    setPosition: (view, coordinates) => {
      const model = view.model;
      model.resize(
        Math.max(coordinates.x, 1),
        Math.max(coordinates.y, 1)
      );
    }
  });

  _tools() {
    if (this.element == null) return;
    let currentScale = this.paper.scale().sx
    const type = this.element.model.get('type')
    const rect = new g.Rect(this.element.getBBox())
    const orbit = new g.Ellipse(rect.center(), rect.width / 2, rect.height / 2);

    let removeButtonPosition = { scale: 1.3, offset: { x: -4, y: -4 }, }

    const resize = new this.ResizeTool({ selector: 'body' });
    resize.children[0].attributes['xlink:href'] = this.icons.resize;

    if (type === 'standard.Ellipse') {
      const ellipsePointOrigin = orbit.intersectionWithLineFromCenterToPoint(rect.origin());
      ellipsePointOrigin.offset(- this.element.getBBox().x,
                                - this.element.getBBox().y);

      removeButtonPosition.x = (ellipsePointOrigin.x - (5 * currentScale))
      removeButtonPosition.y = (ellipsePointOrigin.y - (5 * currentScale))
      const ellipsePointCorner = orbit.intersectionWithLineFromCenterToPoint(rect.corner());
      ellipsePointCorner.offset(- rect.bottomRight().x - 14,
                                - rect.bottomRight().y - 14);
      resize.children[0].attributes.x = ellipsePointCorner.x;
      resize.children[0].attributes.y = ellipsePointCorner.y
    } else {
      resize.children[0].attributes.x = -19;
      resize.children[0].attributes.y = -19;
    }

    const removeButton = new elementTools.Remove(removeButtonPosition);

    const connect = new elementTools.Connect({
      focusOpacity: 0,
      useModelGeometry: true,
      markup: this.getConnectToolMarkup()
    })

    const toolsArray = this._hasConnectedLink() ? [resize, connect] : [removeButton, resize, connect]
    this.element.addTools(new dia.ToolsView({ tools: toolsArray }));
  }

  getConnectToolMarkup() {
    const { width, height } = this.element.model.size();
    const type = this.element.model.get('type')
    let tagName = 'rect'
    let options = {}
    if (type == 'standard.Ellipse') {
      tagName = 'ellipse'
      options = {
        rx: width / 2,
        ry: height / 2,
        cx: width / 2,
        cy: height / 2,
      }
    } else {
      options = {
        width: width,
        height: height,
        rx: 5,
        ry: 5,
      }
    }
    return [{
      tagName: tagName,
      attributes: {
        fill: 'none',
        strokeWidth: 8,
        strokeOpacity: 0,
        stroke: '#4666E5',
        cursor: 'cell',
        ...options
      }
    }];
  }

  _hasConnectedLink() {
    if (this.element == null) return;
    const model = this.element.model
    const connectedLinks = this.graph.getConnectedLinks(model)
    return connectedLinks.length > 0 ? true : false
  }
}

