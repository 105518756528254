import { Controller } from '@hotwired/stimulus'
import { showModal, hideModal } from '../../helpers/modals';
import { post } from '@rails/request.js'
import { getControllerByIdentifier } from 'helpers'
export default class extends Controller {
  static targets = []
  connect() {
    console.log('Connected')
  }


  showConfirmationModal () {
    showModal('rollback_confirmation')
    return new Promise(resolve => {
      this.resolve = resolve
    })
  }

  async rollback(event) {
    let panelController = getControllerByIdentifier(this, 'admin--panel-form');
    if (panelController.formChanged) { return }
    event.preventDefault()
    const decision = await this.showConfirmationModal()
    if (decision === 'save') {
      const url = new URL(event.params.url)
      let dData = new FormData();
      dData.set('parsing_result_id', event.params.resultId);
      const response = await post(url, {
        query: dData,
        responseKind: 'turbo-stream'
      })
      if (response.ok) {
        console.log('okk')
      } else {
        console.log(response.response.statusText)
      }
    }
  }

  saveAction () {
    this.resolve('save')
  }

  cancelAction () {
    this.resolve('cancel')
    hideModal('modal_rollback_confirmation_dialog')
  }
}
