
const dialogRegistry = [];

export function addDialog(dialogId, type = 'overlay') {
  dialogRegistry.push({
    id: dialogId,
    type: type
  });
}

export function removeDialog(dialogId) {
  const idx = dialogRegistry.findIndex(item => item.id === dialogId);
  if (idx > -1) {
    dialogRegistry.splice(idx, 1);
  }
}

export function removeLast() {
  dialogRegistry.pop();
}


export function isOpened(dialogId) {
  return dialogRegistry.findIndex(item => item.id === dialogId) > -1;
}

export function getLast() {
  if (dialogRegistry.length > 0) {
    return dialogRegistry[dialogRegistry.length - 1];
  } else {
    return null;
  }
}

export function getLastId() {
  if (dialogRegistry.length > 0) {
    return dialogRegistry[dialogRegistry.length - 1].id;
  } else {
    return '';
  }
}

export function dialogs() {
  return dialogRegistry
}