import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon', 'field']

  toggleVisibility(event) {
    event.preventDefault()
    if (this.fieldTarget.type == 'password') {
      this.fieldTarget.setAttribute('type', 'text')
      this.iconTarget.classList.replace('fa-eye','fa-eye-slash')
    } else {
      this.fieldTarget.setAttribute('type', 'password')
      this.iconTarget.classList.replace('fa-eye-slash','fa-eye')
    }
  }
}
