import { Controller } from '@hotwired/stimulus'
import _ from 'lodash'
import { showModal, hideModal } from '../../helpers/modals'
import { flashEffect } from 'helpers/effects'

export default class extends Controller {
  loader;
  connect() {
    this.loader = document.querySelector('.page-loader');
  }

  submit(event) {
    if (event.detail.success) {
      const box = event.currentTarget.closest('.modal-content-wrapper')
      const boxId = box.id
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      this.hide(boxId);
      frame.src = ''
    } else {
      this.loader.style.display = 'none';
    }
  }

  disconnect() {

  }

  close(event) {
    event.preventDefault()
    const box = event.currentTarget.closest('.modal-content-wrapper')
    const boxId = box.id
    this.hide(boxId);
    if (box) {
      const frameId = box.dataset.frameId;
      const frame = document.querySelector(`turbo-frame#${frameId}`);
      frame.src = '';
    }
    localStorage.removeItem('statementsForSuggestion');
  }

  show(event) {
    const controller = this
    let boxes = controller.element.querySelectorAll('.result-box')
    boxes.forEach((box) => {
      box.classList.remove('selected');
    });
    let box = event.currentTarget.closest('.result-box')
    const boxId = event.currentTarget.dataset['boxId']
    showModal(boxId)
    box.classList.add('selected')
    this._setStatements(box)
  }

  hide(boxId) {
    hideModal(boxId)
    localStorage.removeItem('statementsForSuggestion');
  }

  setChangedVariableToUrl(event) {
    let box = event.currentTarget.closest('.result-box')
    let getSuggestionLink = box.querySelector('a.get-suggestion')
    let url = new URL(getSuggestionLink.getAttribute('href'))
    let searchParams = url.searchParams
    searchParams.set('variable', event.currentTarget.value)
    url.search = searchParams.toString();
    let newUrl = url.toString();
    getSuggestionLink.href = newUrl;
  }

  setMetaVariable(event) {
    let formGroup = event.currentTarget.closest('.modal-form')
    let radioButtonGroup = formGroup.querySelectorAll('[name^="meta_variable"]')
    let checked = Array.from(radioButtonGroup).find(
      (radio) => radio.checked
    );
    if (_.isNil(checked)) {
      flashEffect(this, {
        message: 'Select one from the list or create a new Meta Variable',
        wrapperClass: 'alert-error',
        icons: ['fa-solid', 'fa-triangle-exclamation']
      })
      return
    }
    let checkedValue = checked.value
    if (checkedValue == 'custom') {
      checkedValue = formGroup.querySelector('#new_meta_variable').value
    }
    let selectedResultBox = this.element.querySelector('.result-box.selected')
    let metaVariableField = selectedResultBox.querySelector('[name$="[meta_variable]"]')

    if (!_.isEmpty(checkedValue)) {
      metaVariableField.value = checkedValue
      metaVariableField.dispatchEvent(new Event('change'))
      hideModal('modal_suggestions_dialog')
    }
  }

  selectCustomMetaVariable(event) {
    let formGroup = event.currentTarget.closest('.form-group')
    let customMetaVariableField = formGroup.querySelector('#meta_variable_custom')
    customMetaVariableField.checked = true;
  }

  _setStatements(box) {
    let statementsFields = box.querySelectorAll('input[name$="[][items][][item]"]')
    let statementsList = Array.from(statementsFields).map(element => element.value)
    localStorage.setItem('statementsForSuggestion', JSON.stringify(statementsList))
  }
}
