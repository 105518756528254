import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  toggleContent(event) {
    const controller = this
    let button = event.currentTarget
    if (controller.hasContentTarget) {
      controller.contentTarget.classList.toggle('hidden')
      if (controller.contentTarget.classList.contains('hidden')) {
        this.setCollapsed(button)
      } else {
        this.setExpanded(button)
      }
    }
  }

  setExpanded(button) {
    let icon = button.querySelector('i.indicator.icon');
    button._tippy.setContent('Collapse')
    icon.classList.remove('collapsed')
    icon.classList.add('expanded')
  }

  setCollapsed(button) {
    let icon = button.querySelector('i.indicator.icon');
    button._tippy.setContent('Expand')
    icon.classList.remove('expanded')
    icon.classList.add('collapsed')
  }

  disconnect() {}
}

